import React from 'react'
import { Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Section from '@components/Section'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'

export default () => (
  <>
    <Seo title='Terms of Use' />
    <Divider />
    <Stack>
      <Main>
        <Section>
          <PageTitle header="Terms of Use" />
        </Section>
        <Divider />
        <Section>
          <PageTitle running='Thank you so much for your visiting at www.zenvava.com(the “Website”, the “Service”).
          These Terms of Use (“Terms”, “Terms of Use”) should apply when you view or use our website.
          Please read these Terms of Use carefully before viewing or using our website.
          By accessing or using our Service you agree to be bound by these Terms.
          If you disagree with any part of these terms then you may not access or use our Service.'
          />
        </Section> 
        <Divider />
        <Section>
          <PageTitle subheader='Legal Information'
          running='This website is owned and operated by Dai Ichi Kaori Boueki LLC(“we”, “us”, “our”) registered in Konosu City, Saitama, Japan.'/>
        </Section>
        <Divider />
        <Section>
          <PageTitle subheader='Terms of Sale and Payment'
          running='
          We recommend you make your account and log in with your account when you place the order.
          Currently, we accept PayPal payment via PayPal secure pages.
          We appreciate if you cleared your payment within 3 days of your purchase.
          Please be aware your order items will be shipped to your PayPal address if you make payment via PayPal.
          You must submit accurate and correct information both on our website and PayPal payment pages.'
          />
        </Section>
        <Divider />
        <Section>
          <PageTitle subheader='Business Partners'
          running='
          We contract with outside business partners such as the makers and the couriers to offer you better experiences when you use this website.
          These outside partners collect your shipping address and phone number information to refine and improve the delivery quality.
          We do not allow these outside partners to collect any other critical information.
          All the outside cooperation partners are contractually required to maintain all the information collected for us in conformance with this policy.'
          />
        </Section>
        <Divider />
        <Section>
          <PageTitle subheader='Currency'
          running='
          We basically have the prices with USD(United States Dollar) on this website www.zenvava.com.
          So the base charging currency is USD, and you will pay with USD via PayPal payment platform.'
          />
        </Section>
        <Divider />
        <Section>
          <PageTitle subheader='Stock Quantities and Availability'
          running='
          We are trying to ensure all the item stock quantities are accurate and correct.
          However, incorrect quantities may display due to unexpected reasons.
          If we notice our errors in the quantity of any items which you have ordered, we will inform you as soon as possible and give you the option of reconfirming your order or canceling your order.
          If we are not able to contact you within 7 business days after your purchase, we are very sorry, we would treat your order as canceled.
          In this case, you will receive the full refund via PayPal.
          Also we reserve the right to correct any errors,
          inaccuracies and to update information as correct at any time without notice on this website.'
          />
        </Section>
        <Divider />
        <Section>
          <PageTitle subheader='Product Descriptions'
          running='
          We are trying to ensure all the item descriptions are accurate and correct.
          However, there may be some difficulties especially in the following attentions:'
          />
          <ul>
          <li>Size:<br />
          The sizing of most items is in the Asian size.
          So please kindly check every measurement on every item pages according to the size chart.
          If you have any questions about the size, please feel free to message us before placing your order.
          </li>
          <li>
          Color:<br />
          We always do our best to display as accurately as possible the colors of the items on the website.
          However, we are not able to guarantee that your monitor’s display of any color will be accurate because the colors will depend on your device and monitor setting.
          If you have any questions about the color, please feel free to message us before placing your order.
          </li>
          <li>Price:<br />
          The item may be listed at an incorrect price due to typographical error or system error.
          We reserve the right to refuse or cancel any orders placed for the item listed at the incorrect price.
          If you have any questions about the price, please feel free to message us before placing your order.
          </li>
          </ul>
        </Section>
        <Divider />
        <Section>
          <PageTitle subheader='Delivery'
          running='
          All the items will be shipped from the relevant brand warehouses in China or Japan via international shipping.
          Basically, each brand items will be shipped from each brand warehouses.
          So, if you purchase multiple brands, we will first let these multiple packages put into one package together,
          then ship it from the nearest port.
          In this case, please be aware there will be 1-2 more extra handling days needed before the final shipping.
          Please be aware of international shipping can take up to 4-12 days or more to arrive.
          Delivery time depends on your destination, holidays, customs, weather and other various factors.
          There may be delay for some reasons, but we can not control the situation once they have been shipped.
          We are so sorry, we are not responsible for damages and losses, or the failure delivery.
          We cannot control over these accidents during shipping. However, we will do our best for you to be a happy customer in every possible way.
          Please contact us at first, and let us know the situation.
          '
          />
        </Section>
        <Divider />
        <Section>
          <PageTitle subheader='Shipping Charge and Custom Duties'
          running='
          The shipping charge depends on your destination countries.
          You need to pay for the shipping charge when placing your order.
          Also, please kindly note you are responsible for any and all the customs duties and charges that may be incurred at your country’s airport.
          We recommend checking out your country’s policies regarding the import for personal use.
          '
          />
        </Section>
        <Divider />
        <Section>
          <PageTitle subheader='Returns and Refunds'
          running='
          If you are not satisfied with your items, you can return to us if the original conditions are kept.
          Please contact us within the 7 days of your receipt so that we will tell you the return address.
          We would appreciate you pay for the return shipping fee if your return reason is not due to our store’s mistake.
          We will refund you all amount via PayPal after receiving the items in good conditions.
          '
          />
        </Section>
        <Divider />
        <Section>
          <PageTitle subheader='Exchanges'
          running='
          We are so sorry, but we are not able to accept international exchanges for the different sizes or the different colors.
          We would recommend you could contact us at first before placing your order if you have any questions or concerns about the sizes, colors and etc.
          Thank you so much for your understanding.'
          />
        </Section>
        <Divider />
        <Section>
          <PageTitle subheader='Faulty Item'
          running='
          If you see the item is faulty, please message us and provide your name and order number, and the details of the item conditions and the item images within the 7 days of your receipt.
          We will contact to the makers and make sure the item is faulty from the practical point of view, then refund you as soon as possible via PayPal.'
          />
        </Section>
        <Divider />
        <Section>
          <PageTitle subheader='Copyrights'
          running='
          The content of the pages of this website is for your personal use only.
          Any and all content, data, graphics, photographs, images, audio, video, trademarks, service marks,
          trade names and other information including the “look and feel” of the website contained in this website are proprietary to Zenvava and/or third-party licensors.
          You may not post, modify, distribute, transmit, reproduce, publicly display, publicly perform or use any these copyrighted materials without obtaining the prior written consent of the owner.
          The content is protected by Japan, China and international copyright and trademark laws.
          '
          />
        </Section>
        <Divider />
        <Section>
          <PageTitle subheader='Ratings and Reviews'
          running='
          Zenvava may enable you to help others make smart shopping decisions by providing ratings and reviews about your experience with various brands’ items.
          When you provide such ratings and reviews and allow us to share them with others publicly, we need to obtain certain rights to reproduce your rating and review.
          When you provide a rating and review, you represent and warrant that it is your original work and that you have the right and authority to grant us a royalty-free worldwide license to reproduce,
          store, transmit, display, distribute, use, edit, translate, combine with other materials, without limitation,
          all or any part of the rating and review in any manner and media. You can waive any and all moral rights that you may have in the rating and review with respect to the uses contemplated herein.
          '
          />
        </Section>
        <Divider />
        <Section>
          <PageTitle subheader='Links to other websites'
          running='
          Our website may contain links to other websites of interest.
          However, once you have used these links to leave our site, you should note that we do not have any control over these other websites.
          Therefore, we cannot be responsible for the protection and privacy of any information which you provide whilst visiting such sites.
          You could access them at your own risk. You should exercise caution and look at the privacy statement and terms applicable to the website in question.
          '
          />
        </Section>
        <Divider />
        <Section>
          <PageTitle subheader='Privacy Policy'
          running='
          We respect the privacy of all users.
          Please kindly refer to the Privacy Policy page. When you view or use the website, you signify your agreement to this Privacy Policy.'
          />
        </Section>
        <Divider />
        <Section>
          <PageTitle subheader='Disclaimer'
          running='
          The content displayed on this website is provided “as is” without any guarantees, conditions or warranties as to its accuracy.'
          />
        </Section>
        <Divider />
        <Section>
          <PageTitle subheader='Notice'
          running='
          We reserve the right to amend and update these Terms of Use at any time.
          All amendments will be posted online. However, continued use of this website will be deemed to constitute acceptance of the new Terms of Use.
          You should check this page from time to time to ensure that you are happy with any changes.'
          />
        </Section>
        <Divider />
        <Section>
          <p>
          (Effective date: 27 January, 2016)<br />
          (Last Update: 7 August, 2020)
          </p>
        </Section>
      </Main>
    </Stack>
  </>
)
